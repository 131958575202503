// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, useLocation } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import Cfzohointegration12 from "../../blocks/cfzohointegration12/src/Cfzohointegration12";
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import GoogleCalendarSync from "../../blocks/googlecalendarsync/src/GoogleCalendarSync";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import Analytics from "../../blocks/analytics/src/Analytics";
import Location from "../../blocks/location/src/Location";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Cfcoursecreation55 from "../../blocks/cfcoursecreation55/src/Cfcoursecreation55";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import PhotoLibrary from "../../blocks/photolibrary/src/PhotoLibrary";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import VideoLibrary from "../../blocks/videolibrary/src/VideoLibrary";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import Subscriptionbilling2 from "../../blocks/subscriptionbilling2/src/Subscriptionbilling2";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import Videos from "../../blocks/videos/src/Videos";
import Notifications from "../../blocks/notifications/src/Notifications";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import ShoppingCartOrders from "../../blocks/shoppingcart/src/ShoppingCartOrders";
import AddShoppingCartOrderItem from "../../blocks/shoppingcart/src/AddShoppingCartOrderItem";
import Download from "../../blocks/download/src/Download";
import DownloadUpload from "../../blocks/download/src/DownloadUpload";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import InventoryManagement from "../../blocks/inventorymanagement/src/InventoryManagement";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import ICalendarSupport from "../../blocks/icalendarsupport/src/ICalendarSupport";
import CameraAccess from "../../blocks/cameraaccess/src/CameraAccess";
import AutomaticCheckoutCalculation from "../../blocks/automaticcheckoutcalculation/src/AutomaticCheckoutCalculation";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import Gamification from "../../blocks/educationalgamification/src/Gamification";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import DocumentOpener from "../../blocks/documentopener/src/DocumentOpener";
import Chat from "../../blocks/chat/src/Chat";
import ViewChat from "../../blocks/chat/src/ViewChat";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Notificationsettings from "../../blocks/notificationsettings/src/Notificationsettings";
import Cfintergratingwithknowlarity from "../../blocks/cfintergratingwithknowlarity/src/Cfintergratingwithknowlarity";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";


const routeMap = {
Cfzohointegration12:{
 component:Cfzohointegration12,
path:"/Cfzohointegration12"},
Tasks:{
 component:Tasks,
path:"/Tasks"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
Task:{
 component:Task,
path:"/Task"},
GoogleCalendarSync:{
 component:GoogleCalendarSync,
path:"/GoogleCalendarSync"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Location:{
 component:Location,
path:"/Location"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Cfcoursecreation55:{
 component:Cfcoursecreation55,
path:"/Cfcoursecreation55"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
PhotoLibrary:{
 component:PhotoLibrary,
path:"/PhotoLibrary"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
VideoLibrary:{
 component:VideoLibrary,
path:"/VideoLibrary"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
Subscriptionbilling2:{
 component:Subscriptionbilling2,
path:"/Subscriptionbilling2"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
Videos:{
 component:Videos,
path:"/Videos"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
ShoppingCartOrders:{
 component:ShoppingCartOrders,
path:"/ShoppingCartOrders"},
AddShoppingCartOrderItem:{
 component:AddShoppingCartOrderItem,
path:"/AddShoppingCartOrderItem"},
Download:{
 component:Download,
path:"/Download"},
DownloadUpload:{
 component:DownloadUpload,
path:"/DownloadUpload"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
InventoryManagement:{
 component:InventoryManagement,
path:"/InventoryManagement"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
OrderManagement:{
 component:OrderManagement,
path:"/OrderManagement"},
ICalendarSupport:{
 component:ICalendarSupport,
path:"/ICalendarSupport"},
CameraAccess:{
 component:CameraAccess,
path:"/CameraAccess"},
AutomaticCheckoutCalculation:{
 component:AutomaticCheckoutCalculation,
path:"/AutomaticCheckoutCalculation"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
Gamification:{
 component:Gamification,
path:"/Gamification"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
DocumentOpener:{
 component:DocumentOpener,
path:"/DocumentOpener"},
Chat:{
 component:Chat,
path:"/Chat"},
ViewChat:{
 component:ViewChat,
path:"/ViewChat"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Notificationsettings:{
 component:Notificationsettings,
path:"/Notificationsettings"},
Cfintergratingwithknowlarity:{
 component:Cfintergratingwithknowlarity,
path:"/Cfintergratingwithknowlarity"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  return (
    <BuilderProvider>
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        <WebRoutesGenerator routeMap={routeMap} />
        <Outlet />
        <AlertBlock />
      </View>
    </BuilderProvider>
  );
}

export default App;